import React, { useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import SurveyResponseManager from "managers/survey_response/surveyResponseManager";
import {ClientSurveyTemplate} from "models/clientSurvey";

let DownloadMultipleQualtricsResponses = (props : DownloadMultipleQualtricsResponseParams) => {
  const [surveyIds, setSurveyIds] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const options = {month: 'numeric', day: 'numeric',year: 'numeric', hour: 'numeric', minute: 'numeric' };
  let surveyResponseManager = new SurveyResponseManager(props.smToken);

  const submitDownloadRequest = () => {
    if (surveyIds.length === 0 || !startDate || !endDate) {
      alert("Please fill out the form properly");
    } else {
      surveyResponseManager
        .downloadResponses(surveyIds, startDate, endDate)
        .then((response) => {
          if (response) {
            props.handleToast(true);
          } else {
            alert("Request failed");
          }
        });
    }
  };
  const surveyOptionsChanged = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedOptions = event.target.selectedOptions;
    let values = [];
    for (let i = 0; i < selectedOptions.length; i++)
      values.push(selectedOptions[i].value);
    setSurveyIds(values);
  };
  return (
      <Col lg="12" className="ResponseDownload">
        
      <Col lg="12">
      <h2>Multiple Responses</h2>
        <Form.Group controlId="multipleSurveyIds">
          <Form.Label>Qualtrics Surveys</Form.Label>
          <select
            value={surveyIds}
            onChange={surveyOptionsChanged}
            multiple
            className={"form-control surveySelect"}
          >
            <option value={0} disabled>
              Select Survey
            </option>
            {props.surveys.map((item, index) => {
              
              let startDate = new Date(item.startDate);
              let endDate = new Date(item.endDate);

              return (
                <option key={index} value={item.id}>
                  {item.title + '(' + startDate.toLocaleDateString("en-US", options) + ' -> ' + endDate.toLocaleDateString("en-US", options) }
                </option>
              );
            })}
          </select>
        </Form.Group>
      </Col>

      <Col lg="12">
        <Form.Group controlId="singleResponseDownload">
          <Form.Label>Start Date</Form.Label>
          <Form.Control
            type="date"
            defaultValue={startDate}
            onChange={(event) => {
              setStartDate(event.target.value);
            }}
          />
        </Form.Group>
      </Col>

      <Col lg="12">
        <Form.Group controlId="singleResponseDownload">
          <Form.Label>End Date</Form.Label>
          <Form.Control
            type="date"
            defaultValue={endDate}
            onChange={(event) => {
              setEndDate(event.target.value);
            }}
          />
        </Form.Group>
      </Col>

      <Col lg="12">
        <Button variant="primary" block onClick={()=> {submitDownloadRequest();  props.handleClose(true)}}>
          Download
        </Button>
      </Col>
      </Col>
  );
};
interface DownloadMultipleQualtricsResponseParams {
    surveys: ClientSurveyTemplate[];
    handleToast: any;
    handleClose: any;
    smToken: string;
}
export default DownloadMultipleQualtricsResponses;
