import React, { useEffect, useState } from "react";
import { retrieveToken, getSurveyTypes } from "utils";
import { Link } from "react-router-dom";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import SurveyManager from "managers/survey_definition/surveyManager";
import { Helmet } from 'react-helmet'

export default function SurveyList(props) {
  let token = retrieveToken();
  let surveyManager = new SurveyManager(token);
  const [surveys, setSurveys] = useState([]);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    surveyManager.getSurveys().then((surveys) => {
      console.log(surveys);
      setSurveys(surveys);
      setLoading(false);
    });
  }, []);

  const { SearchBar } = Search;
  const columns = [
    {
      dataField: "id",
      text: "",
      sort: true,
      headerStyle: () => {
        return { width: '5%' };
      },
    },
    {
      dataField: "isActive",
      formatter: formatStatus,
      text: "Active",
      sort: true,
      headerStyle: () => {
        return { width: '5%' };
      },
    },
    {
      dataField: "clientName",
      text: "Client",
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: "title",
      text: "Title",
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: "surveyTypeText",
      text: "Survey Type",
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: "participantTypeText",
      text: "Participant Type",
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },

    {
      dataField: "qualtricsSurveyId",
      text: "Qualtrics Id",
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: "isAutoImport",
      text: "Auto Import",
      sort: true,
      formatter: autoImportFormatter,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: "startDate",
      text: "Start Date",
      sort: true,
      formatter: formatStartDate,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: "endDate",
      text: "End Date",
      sort: true,
      formatter: formatEndDate,
      headerStyle: () => {
        return { width: '5%' };
      },
    },
    {
      text: "",
      dataField: "",
      formatter: actionFormatter,
      headerStyle: () => {
        return { width: "15%" };
      },
    },
  ];

  function formatStatus(cell,row){
    if  (row.isActive) {
      return <>✅</> 
    }
    else {
      return <>❌</>;
    }

  }

  function autoImportFormatter(cell,row){
    if (row.isAutoImport === true){
      return (<div>✅</div>)
    }
    else{
      return (<div></div>)
    }
  }
  function formatStartDate(cell,row){
    let options = {
      year: 'numeric', month: 'numeric', day: 'numeric',
      hour: 'numeric', minute: 'numeric', second: 'numeric',
      hour12: true,
      timeZone: 'America/New_York'
    };
    return (
      <>
        <span>{new Intl.DateTimeFormat('en-US', options).format(new Date(Date.parse(row.startDate)))}</span>
      </>
    );
  }
  function formatEndDate(cell,row){
    let options = {
      year: 'numeric', month: 'numeric', day: 'numeric',
      hour: 'numeric', minute: 'numeric', second: 'numeric',
      hour12: true,
      timeZone: 'America/New_York'
    };
    return (
      <>
        <span>{new Intl.DateTimeFormat('en-US', options).format(new Date(Date.parse(row.endDate)))}</span>
      </>
    );
  }
  function actionFormatter(cell, row) {
    return (
      <>
        <Link
          className="btn btn-sm btn-secondary"
          to={"/survey-definition/survey/" + row.id}
        >
          Edit
        </Link>
        &nbsp;
        <Link
          className="btn btn-sm btn-primary"
          to={"/survey-definition/survey/" + row.id + "/build"}
        >
          Build
        </Link>
        &nbsp;
        <Link
          className="btn btn-sm btn-info"
          target="_blank"
          to={"/survey-definition/survey/" + row.id + "/print"}
        >
          Print View
        </Link>
      </>
    );
  }

  return (
    <Container fluid={true}>
      <Helmet><title>StayMetrics Admin - Survey List</title></Helmet>
      <Row>
        <Col lg={12}>
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="#">Survey Definition</Breadcrumb.Item>
            <Breadcrumb.Item href="#">Surveys</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row>
        <Col>
          <h1>Surveys</h1>
        </Col>
      </Row>
      <LoadingOverlay
        active={loading}
        spinner={<BounceLoader />}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(91, 134, 96, 0.5)",
          }),
          content: (base) => ({
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
          }),
        }}
        text="Loading...">
        <>
          <Row>
            <Col lg={12}>
              <ToolkitProvider
                keyField="id"
                data={surveys}
                columns={columns}
                search
              >
                {(props) => (
                  <>
                    <Row>
                      <Col lg={6}>
                        <Link
                          className="btn btn-primary"
                          to="/survey-definition/survey/new"
                        >
                          New Survey
                        </Link>
                      </Col>
                      <Col lg={6} className="text-right">
                        <SearchBar {...props.searchProps} />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <BootstrapTable
                          {...props.baseProps}
                          pagination={paginationFactory()}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </ToolkitProvider>
            </Col>
          </Row>
        </>
      </LoadingOverlay>
    </Container>
  );
}
