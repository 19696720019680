import React from "react";
import SurveyService from "services/survey_definition/survey";
import QuestionManager from "managers/survey_definition/questionManager";
import { retrieveToken } from "utils";
import SurveyManager from "managers/survey_definition/surveyManager";
import AddQuestionCenteredModal from "components/survey_definition/survey/AddQuestionCenteredModal";
import AddSectionCenteredModal from "components/survey_definition/survey/AddSectionCenteredModal";
import CustomToggle from "components/survey_definition/survey/CustomToggle";
import {
  Button,
  Breadcrumb,
  Col,
  Container,
  Row,
  Popover,
  OverlayTrigger,
  Accordion,
  Card
} from "react-bootstrap";
import { ChangedFlag } from "models/changedFlag";
import LoadingOverlay from "react-loading-overlay";
import ReactDragListView from "react-drag-listview";

import BounceLoader from "react-spinners/BounceLoader";
import {
  List,
  BackspaceReverse,
  Plus,
  Pencil,
  QuestionSquareFill,
  ArrowDown,
  ArrowUp
} from "react-bootstrap-icons";
import { Helmet } from 'react-helmet'

class SurveyBuilder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      surveyId: "",
      questions: {},
      surveyData: {},
      loading: false,
      dragSelect: null,
      modalQuestionShow: false,
      modalSectionShow: false,
      sectionId: null,
      questionId: null,
      sectionName: "",
      editQuestion: {},
      editSection: "",
      variableName: "",
      reportCategory: "",
      insertQuestion: {},
      clientSurveyItemId: "",
      isNewQuestion: false,
      isNewSection: false,
      dragObject: "",
      sectionNames: [],
      surveyInfo: {},
      options: {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric',
        hour12: true,
        timeZone: 'America/New_York'
      }
    };

    this.handleAddQuestion = this.handleAddQuestion.bind(this);
    this.handleMoveQuestion = this.handleMoveQuestion.bind(this);
    this.handleUpdateQuestion = this.handleUpdateQuestion.bind(this);
    this.handleAddSection = this.handleAddSection.bind(this);
    this.handleEditSection = this.handleEditSection.bind(this);
    this.addQuestionCallBack = this.addQuestionCallBack.bind(this);
    this.getQuestionValue = this.getQuestionValue.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  getQuestionValue = index => {
    if (this.state.questions.length > 0) return this.state.questions[index];
  };

  handleSave = exit => {
    this.setState({ loading: true });
    let survey = this.state.surveyData;
    let count = 1;

    survey.data.forEach(section => {
      section.contents.forEach(questions => {
        if (questions.changedFlag != ChangedFlag.Deleted) {
          if (questions.changedFlag != ChangedFlag.New)
            questions.changedFlag = ChangedFlag.Update;

          questions.position = count;
          count++;
        }
      });
    });
    this.setState({ surveyData: survey });

    let token = retrieveToken();
    let surveyManager = new SurveyManager(token);
    surveyManager
      .update(this.state.surveyId, this.state.surveyData)
      .then(() => {
        if (exit) {
          window.location.href = "/survey-definition/survey";
        } else {
          this.setState({ loading: true });
          this.setState({ surveyId: this.props.match.params.surveyId });
          surveyManager.getById(this.props.match.params.surveyId).then(x => {
            this.setState({ surveyData: x });
          });
          // survey.data.forEach(section => {
          //   section.contents.forEach(questions => {
          //     questions.changedFlag = ChangedFlag.NoChange;
          //   });
          // });
          this.setState({ loading: false });
        }
      });
  };

  handleSectionMove = (section, direction) => {
    if (section >= 0 && direction !== "") {
      let survey = this.state.surveyData;
      let old = survey.data[section];
      let temp = survey.data[section + direction];
      survey.data[section] = temp;
      survey.data[section + direction] = old;
      this.setState({ surveyData: survey });
    }
  };

  handleAddSection = data_from_child => {
    let survey = this.state.surveyData;
    let new_section = {
      name: data_from_child.section_name,
      changedFlag: ChangedFlag.New,
      contents: []
    };
    survey.data.push(new_section);

    let temp = this.state.sectionNames;
  
    temp.push({label:data_from_child.section_name, value: this.state.sectionNames.length});

    this.setState({ sectionNames: temp });
    this.setState({ editSection: {} });
  };

  handleEditSection = data_from_child => {
    let survey = this.state.surveyData;
    survey.data[this.state.sectionId].name = data_from_child.section_name;
    survey.data[this.state.sectionId].changedFlag = ChangedFlag.Update;
    this.setState({ surveyData: survey });
  };

  handleRemoveSection(section) {
    if (this.state.surveyData.data[section].contents.length > 0) {
      //If section has questions
      if (this.state.surveyData.data[section].changedFlag === ChangedFlag.New) {
        if (this.state.sectionId !== "") {
          let survey = this.state.surveyData;
          survey.data.splice(this.state.sectionId, 1);
          this.setState({ surveyData: survey });
        }
      } else {
        let survey = this.state.surveyData;

        survey.data[section].contents.forEach((item, i) => {
          item.changedFlag = ChangedFlag.Deleted;
          let deleted_question = document.getElementById(
            `question-${section}-${i}`
          );
          deleted_question.parentNode.removeChild(deleted_question);
          this.state.surveyData.data[section].contents[i].changedFlag =
            ChangedFlag.Deleted;
        });
        let deleted_section = document.getElementById(`section-${section}`);
        deleted_section.parentNode.removeChild(deleted_section);
        survey.data[section].changedFlag = ChangedFlag.Deleted;

        this.setState({ surveyData: survey });
      }
    } else {
      //If section has no questions
    }
  }
  handleUpdateQuestion = data_from_child => {
    if (this.state.sectionId !== "" && this.state.questionId !== "") {
      let survey = this.state.surveyData;
      let flag = ChangedFlag.Unknown;

      //if question is new and not saved, keep the updated question as new.
      if (
        survey.data[this.state.sectionId].contents[this.state.questionId]
          .changedFlag === ChangedFlag.New
      )
        flag = ChangedFlag.New;
      //if question is saved, mark the updated question as updated.
      if (
        survey.data[this.state.sectionId].contents[this.state.questionId]
          .changedFlag === ChangedFlag.Unknown
      )
        flag = ChangedFlag.Unknown;

      let temp_question = {
        id: this.state.clientSurveyItemId,
        changedFlag: flag,
        surveyItemId: data_from_child.question.surveyItemId,
        surveyItemWordingId: data_from_child.question.surveyItemWordingId,
        position: 10,
        variableName: data_from_child.variable_name,
        reportCategory: data_from_child.category_name
      };

      survey.data[this.state.sectionId].contents.splice(
        this.state.questionId,
        1,
        temp_question
      );

      this.setState({ surveyData: survey });
    }
  };
  handleAddQuestion = data_from_child => {
    if (this.state.sectionId !== "" && this.state.questionId !== "") {
      let temp_question = {
        id: data_from_child.question.surveyItemId,
        changedFlag: ChangedFlag.New,
        surveyItemId: data_from_child.question.surveyItemId,
        surveyItemWordingId: data_from_child.question.surveyItemWordingId,
        position: 10,
        variableName: data_from_child.variable_name,
        reportCategory: data_from_child.category_name
      };

      let survey = this.state.surveyData;
      survey.data[this.state.sectionId].contents.splice(
        this.state.questionId + 1,
        0,
        temp_question
      );
      this.setState({ surveyData: survey });

      let add_question = document.getElementById(
        `question-${this.state.sectionId}-${this.state.questionId}`
      );
      //add_question.parentNode.appendChild(add_question);

    }
  };
  handleMoveQuestion(section, item, newSection){
    
    // let temp = this.state.surveyData;
    // temp.data[index].contents[i].showMove = false;
    // this.setState({surveyData: temp });

    if (this.state.surveyData.data[section].contents[item]) 
    {
      let temp_question = this.state.surveyData.data[section].contents[item];
      let survey = this.state.surveyData;
      survey.data[section].contents.splice(item, 1);
      temp_question.showMove = false;
      survey.data[newSection].contents.splice(
        this.state.questionId,
        0,
        temp_question
      );
      this.setState({ surveyData: survey });
    }
    
  }

  handleRemoveQuestion(section, item) {
    if (
      this.state.surveyData.data[section].contents[item].changedFlag ===
      ChangedFlag.New
    ) {
      let survey = this.state.surveyData;
      survey.data[section].contents.splice(item, 1);
      this.setState({ surveyData: survey });
    } else {
      let deleted_question = document.getElementById(
        `question-${section}-${item}`
      );
      //deleted_question.parentNode.removeChild(deleted_question);

      let survey = this.state.surveyData;
      survey.data[section].contents[item].changedFlag = ChangedFlag.Deleted;
      this.setState({ surveyData: survey });
    }
  }

  addQuestionCallBack = ev => {};

  componentDidMount() {
    let token = retrieveToken();
    let questionManager = new QuestionManager(token);
    let surveyManager = new SurveyManager(token);
    let surveyData = SurveyService.getBlankSurvey();

    this.setState({ loading: true });
    this.setState({ surveyId: this.props.match.params.surveyId });
    surveyManager.getById(this.props.match.params.surveyId).then(x => {
      surveyManager.getSurveyInfoById(this.props.match.params.surveyId).then(si =>{
        this.setState({ surveyInfo: si.data });
      });

      this.setState({ surveyData: x });
      let sectionNames = [];
      x.data.forEach((section, i)=> {
        sectionNames.push({label:section.name, value: i});
      });

      this.setState({ sectionNames: sectionNames });
    });

    questionManager.getQuestions().then(x => {
      let question_options = [];
      if (x.length > 0) {
        x.forEach(item => {
          item.wordings.forEach(q => {
            question_options[q.id] = {
              surveyItemWordingId: q.id,
              value: q.id,
              question_number: item.questionNumber,
              label: item.questionNumber + ": " + q.text,
              default: q.default,
              surveyItemId: item.id,
              responseSetDescription: item.responseSetDescription,
              choices: item.choices
            };
          });
        });
        this.setState({ questions: question_options });
        this.setState({ loading: false });
      }
    });
  }

  render() {
    const that = this;
    const dragQuestionProps = {
      onDragEnd(fromIndex, toIndex) {
        fromIndex--;
        toIndex--;

        if (fromIndex >= 0 && toIndex >= 0) {
          let survey = that.state.surveyData;
          const q = survey.data[that.state.sectionId].contents.splice(fromIndex,1)[0];
          survey.data[that.state.sectionId].contents.splice(toIndex, 0, q);
          that.setState({ surveyData: survey }); 
        }
      },
      nodeSelector: ".dragdrow",
      handleSelector: ".dragdrow"
    };

    return (
      <Container fluid={true}>
      <Helmet><title>StayMetrics Admin - Build Survey</title></Helmet>
        <Row>
          <Col lg={12}>
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item href="/survey-definition/survey">
                Surveys
              </Breadcrumb.Item>
              <Breadcrumb.Item href="#">Build Survey</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <LoadingOverlay
              active={this.state.loading}
              spinner={<BounceLoader />}
              styles={{
                overlay: base => ({
                  ...base,
                  background: "rgba(91, 134, 96, 0.5)"
                }),
                content: base => ({
                  marginLeft: "auto",
                  marginRight: "auto",
                  textAlign: "center"
                })
              }}
              text="Loading..."
            >
              <Col lg={12} className="sectionTools">
                <Plus
                  className="iconStyle"
                  size={32}
                  onClick={() => {
                    this.setState({ modalSectionShow: true });
                    this.setState({ isNewSection: true });
                    this.setState({ sectionName: "" });
                  }}
                />
              </Col>
              <ReactDragListView {...dragQuestionProps}>
                <Accordion defaultActiveKey="0">
                  {this.state.surveyData.data &&
                    this.state.surveyData.data.map((section, index) => {
                      return (
                        <Card key={index}>
                          <div className="dragdrow">
                            <Card.Header id={`section-${index}`}>
                              <Row className="sectionStyle">
                                <Col lg={4}>
                                  <Row>
                                    <Col lg={1}>
                                      <span
                                        onClick={() => {
                                          this.setState({ sectionId: index });
                                        }}
                                      >
                                        <CustomToggle eventKey={index} />
                                      </span>
                                    </Col>
                                    <Col lg={11}>
                                      <h3>{section.name}</h3>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg={4}>&nbsp;</Col>
                                <Col lg={4} className="sectionTools">
                                  <QuestionSquareFill
                                    className="iconStyle"
                                    size={32}
                                    onClick={() => {
                                      this.setState({
                                        modalQuestionShow: true
                                      });
                                      this.setState({ sectionId: index });
                                      this.setState({
                                        questionId: section.contents.length - 1
                                      });
                                      this.setState({ isNewQuestion: true });
                                      this.setState({ editQuestion: {} });
                                      this.setState({ variableName: "" });
                                      this.setState({ reportCategory: "" });
                                    }}
                                  />
                                  <Pencil
                                    className="iconStyle"
                                    size={32}
                                    onClick={() => {
                                      this.setState({ isNewSection: false });
                                      this.setState({ sectionId: index });
                                      this.setState({
                                        sectionName: section.name
                                      });
                                      this.setState({ modalSectionShow: true });
                                    }}
                                  />
                                  <BackspaceReverse
                                    onClick={() => {
                                      this.setState({ sectionId: index });
                                      if (
                                        window.confirm(
                                          "Deleting this section will also delete all the questions. Are you ok with this? There are " +
                                            this.state.surveyData.data[index]
                                              .contents.length +
                                            " Questions that will go bye bye. Go ahead and think about it"
                                        )
                                      )
                                        this.handleRemoveSection(index);
                                    }}
                                    className="iconStyle"
                                    size={32}
                                  />
                                  {index ? (
                                    <ArrowUp
                                      className="iconStyle"
                                      size={32}
                                      onClick={() => {
                                        this.handleSectionMove(index, -1);
                                      }}
                                    />
                                  ) : null}
                                  {index !==
                                  this.state.surveyData.data.length - 1 ? (
                                    <ArrowDown
                                      className="iconStyle"
                                      size={32}
                                      onClick={() => {
                                        this.handleSectionMove(index, 1);
                                      }}
                                    />
                                  ) : null}
                                </Col>
                              </Row>
                            </Card.Header>
                            {section.contents &&
                              section.contents.map((item, i) => {
                                let q = "";
                                if (this.state.questions[item.surveyItemWordingId] !== undefined)
                                  q = this.state.questions[item.surveyItemWordingId];
                                  
                                  let itemClass = "questionStyle";

                                  if (item.changedFlag === 4)
                                    itemClass += " deletedClass";
                                  else if (item.changedFlag === 1)  
                                    itemClass += " addedClass";

                                  if (q.label){
                                    q.label = q.label.split(":").pop();
                                  }
                                return (
                                  <Accordion
                                    className="dragdrow"
                                    id={`question-${index}-${i}`}
                                    key={i}
                                    onDragStart={() => {
                                      this.setState({ dragObject: "question" });
                                    }}
                                  >
                                    <Card.Body key={i} onMouseDown={()=>{this.setState({sectionId: index })}}>
                                      <div className={itemClass} >
                                        <Row className="questionStyle">
                                          <div className="sectionNumber">
                                          {this.state.surveyData.data[index].contents[i].showMove ? 
                                          <>
                                          <select onChange={ (e) => {this.handleMoveQuestion(index, i, e.target.value)}}>
                                          <option key={9999} value="">Move To</option>
                                            {this.state.sectionNames && this.state.sectionNames.map( (s)=>{
                                                return (<option key={s.value} value={s.value}>{s.label}</option>)}
                                            )}
                                          </select>
                                          <button onClick={()=>{
                                          
                                            let temp = this.state.surveyData;
                                                  temp.data[index].contents[i].showMove = false;
                                                  this.setState({surveyData: temp });
                                            }}>x</button>
                                          </> :
                                            <span className="sectionNumber"  onClick={() =>{
                                                  let temp = this.state.surveyData;
                                                  temp.data[index].contents[i].showMove = true;
                                                  this.setState({surveyData: temp });
                                                }}>
                                              {q.question_number}
                                            </span>
                                          }      
                                          </div>
                                          <div className="sectionQuestion">
                                            {q.label}
                                          </div>
                                          <div className="sectionTool">
                                            <QuestionSquareFill
                                              className="iconStyle"
                                              size={32}
                                              onClick={() => {
                                                this.setState({
                                                  modalQuestionShow: true
                                                });
                                                this.setState({
                                                  sectionId: index
                                                });
                                                this.setState({
                                                  questionId: i
                                                });
                                                this.setState({
                                                  isNewQuestion: true
                                                });
                                                this.setState({
                                                  editQuestion: {}
                                                });
                                                this.setState({
                                                  variableName: ""
                                                });
                                                this.setState({
                                                  reportCategory: ""
                                                });
                                              }}
                                            />
                                            <Pencil
                                              className="iconStyle"
                                              size={32}
                                              onClick={() => {
                                                this.setState({
                                                  sectionId: index
                                                });
                                                this.setState({
                                                  questionId: i
                                                });
                                                this.setState({
                                                  editQuestion: this.getQuestionValue(
                                                    item.surveyItemWordingId
                                                  )
                                                });
                                                this.setState({
                                                  clientSurveyItemId: item.id
                                                });
                                                this.setState({
                                                  variableName: this.state
                                                    .surveyData.data[index]
                                                    .contents[i].variableName
                                                });
                                                this.setState({
                                                  reportCategory: this.state
                                                    .surveyData.data[index]
                                                    .contents[i].reportCategory
                                                });
                                                this.setState({
                                                  isNewQuestion: false
                                                });
                                                this.setState({
                                                  modalQuestionShow: true
                                                });
                                              }}
                                            />
                                            <BackspaceReverse
                                              onClick={() =>
                                                this.handleRemoveQuestion(
                                                  index,
                                                  i
                                                )
                                              }
                                              className="iconStyle"
                                              size={32}
                                            />
                                            <OverlayTrigger
                                              trigger="hover"
                                              delay={{ show: 250, hide: 400 }}
                                              key={i}
                                              placement="right"
                                              overlay={
                                                <Popover
                                                  id={`popover-positioned-${i}`}
                                                >
                                                  <Popover.Title
                                                    className="popTitleStyle"
                                                    as="h3"
                                                  >{q.responseSetDescription}</Popover.Title>
                                                  <Popover.Content>
                                                    <ul
                                                      className="popTitleStyle"
                                                      id={item.id}
                                                    >
                                                      {q.choices &&
                                                        q.choices.map(
                                                          (choice, index) => {
                                                            return (
                                                              <li key={index}>
                                                                {choice.text}
                                                              </li>
                                                            );
                                                          }
                                                        )}
                                                    </ul>
                                                  </Popover.Content>
                                                </Popover>
                                              }
                                            >
                                              <span>
                                                {q.choices &&
                                                  q.choices.length > 0 && (
                                                    <List
                                                      className="iconStyle"
                                                      size={32}
                                                    />
                                                  )}
                                              </span>
                                            </OverlayTrigger>
                                          </div>
                                        </Row>
                                      </div>
                                    </Card.Body>
                                  </Accordion>
                                );
                              })}
                          </div>
                        </Card>
                      );
                    })}
                </Accordion>
              </ReactDragListView>
              <Row>
                <Col lg={12}>&nbsp;</Col>
              </Row>
              <Row>
                <Col lg={12}>&nbsp;</Col>
              </Row>
              <Row>
                <Col lg={12}>&nbsp;</Col>
              </Row>
              <Row className="pinBottom">
              <Col lg={4}>
              <h4 className="blueInfoHeader">{this.state.surveyInfo.surveyTitle}</h4>
              </Col>
                <Col lg={4}>
                  <Button
                    onClick={() => this.handleSave(0)}
                    variant="success"
                    size="lg"
                    type="submit"
                  >
                    Save & Continue
                  </Button>
                  &nbsp;
                  <Button
                    onClick={() => this.handleSave(1)}
                    variant="success"
                    size="lg"
                    type="submit"
                  >
                    Save
                  </Button>
                </Col>
                <Col lg={2}>
                <h4 className="blueInfoHeader">Effective Dates</h4>
                {this.state.surveyInfo.startDate ? <span>{new Intl.DateTimeFormat('en-US', this.options).format(new Date(Date.parse(this.state.surveyInfo.startDate)))}</span> : ''}
                <span> to </span> {this.state.surveyInfo.endDate ? <span>{new Intl.DateTimeFormat('en-US', this.options).format(new Date(Date.parse(this.state.surveyInfo.endDate)))}</span> : ''}
              </Col>
              <Col lg={2}>
                <h4 className="blueInfoHeader">Qualtrics Id</h4>
                <span>{this.state.surveyInfo.qualtricsSurveyId}</span>             
              </Col>
              </Row>
            </LoadingOverlay>
          </Col>
        </Row>

        <AddQuestionCenteredModal
          insert_question={this.handleAddQuestion}
          update_question={this.handleUpdateQuestion}
          is_new_question={this.state.isNewQuestion}
          is_new_section={this.state.isNewSection}
          edit_question={this.state.editQuestion}
          variable_name={this.state.variableName}
          report_category={this.state.reportCategory}
          show={this.state.modalQuestionShow}
          questions={this.state.questions}
          onHide={() => {
            this.setState({ modalQuestionShow: false });
          }}
        />
        <AddSectionCenteredModal
          is_new_section={this.state.isNewSection}
          insert_section={this.handleAddSection}
          update_section={this.handleEditSection}
          section_name={this.state.sectionName}
          show={this.state.modalSectionShow}
          onHide={() => {
            this.setState({ modalSectionShow: false });
          }}
        />
      </Container>
    );
  }
}
export default SurveyBuilder;
