import { SurveyResponse } from "models/surveyResponse/surveyResponse";
import SurveyResponseService from "services/survey_response/surveyResponseService";
import { DownloadResponseRequestDocument } from "models/surveyResponse/downloadResponseRequestEntity";

export default class SurveyResponseManager {

  private surveyResponseService: SurveyResponseService;

  constructor(token: string) {
    this.surveyResponseService = new SurveyResponseService(token);
  }

  getSurveyResponses(filter_problems: boolean, problem_ids: Array<number>, search: string): Promise<SurveyResponse[]> {
    return this.surveyResponseService.getSurveyResponses(filter_problems, problem_ids, search);
  }

  setResponseAction(action: string, items: Array<number>): Promise<boolean> {
    console.log("action-", action, "items-", items)
    switch (action) {
      case "fixed":
        return this.surveyResponseService.fixResponseAction(items)
          .then(() => {
            return true;
          })
        break;
      case "delete":
        return this.surveyResponseService.deleteResponseAction(items)
          .then((response: any) => {
            return response;
          })
        break;
      case "delete-download":
        return this.surveyResponseService.redownloadResponseAction(items);
      case "redownload":
        return this.surveyResponseService.redownloadResponseAction(items)
          .then((response: any) => {
            return response;
          })
        break;
      case "revalidate":
        return this.surveyResponseService.revalidateResponseAction(items)
          .then((response: any) => {
            return response;
          })
        break;
      default:
    }
  }

  downloadSingleResponse(clientSurveyId: number, qualtricsResponseId: string): Promise<boolean> {
    return this.surveyResponseService.downloadSingleResponse(clientSurveyId, qualtricsResponseId)
      .then((response: any) => {
        return response;
      });
  }

  downloadResponses(clientSurveyId: Array<number>, startDate: Date, endDate: Date): Promise<boolean> {
    //prepare date, convert to string, add final hour, minute, second, millisecond.
    let modifiedEndDate = endDate + "T23:59:59.999Z";
    
    return this.surveyResponseService.downloadResponses(clientSurveyId, startDate, modifiedEndDate)
      .then(response => true)
      .catch(ex => false);
  }

  listDownloadResponseRequests(): Promise<DownloadResponseRequestDocument[]> {
    return this.surveyResponseService.listDownloadResponseRequests()
      .then((response: DownloadResponseRequestDocument[]) => {
        return response;
      })
    .catch(()=> []);
  }
}
