import React, {useState, useEffect} from "react";
import {Breadcrumb, Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import ClientUserManager from "managers/client_portal/clientUserManager";
import ClientManager from "managers/clientManager";
import {retrieveToken} from "utils";
import { Helmet } from 'react-helmet'

const { SearchBar } = Search;

export default function ClientUserList() {

  const [users, setUsers] = useState([]);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);

  let token = retrieveToken();
  let clientUserManager = new ClientUserManager(token);
  let clientManager = new ClientManager(token);

  const sendInvite = (id) => {
    clientUserManager.sendInvitation(id)
      .then(() => {
        alert("Invitation sent!");
      });
  }

  useEffect(() => {
    setLoading(true);
    clientManager.getList().then(clients => {
      setClients(clients);

      clientUserManager.getClientUserList().then(users => {
        console.log("users", users);
        users && users.forEach(x => {
          x.activeIcon = x.active ? '✅' : '❌';
          x.action = <><Link className="btn btn-sm btn-primary" to={'/client-portal/client-users/' + x.id}>Edit</Link>&nbsp;{x.msGraphUserId == null ? <button onClick={() => sendInvite(x.id)} className="btn btn-sm btn-secondary">Send Invitation</button> : ''}</>
          let clientList = "";
          let clientName = "";
          x.client.forEach(client=>{
            if (clients.find(c => c.id === client)){
              clientName = clients.find(c => c.id === client).name;
              clientList +=  clientName + ", ";
            }
          })
            x.clientName = clientList;
          });
        if (users)
          setUsers(users);

          setLoading(false);
      });
    });
  }, []);

  const columns = [
    {
      dataField: 'id',
      text: 'ID'
    },
    {
      dataField: 'clientName',
      text: 'Client'
    },
    {
      dataField: 'lastName',
      text: 'Last Name'
    },
    {
      dataField: 'firstName',
      text: 'First Name'
    },
    {
      dataField: 'emailAddress',
      text: 'Email'
    },
    {
      dataField: 'activeIcon',
      text: 'Active'
    },
    {
      dataField: 'action',
      text: ''
    }
  ];

  return (
    <Container fluid={true}>
    <Helmet><title>StayMetrics Admin - Client User List</title></Helmet>
      <Row>
        <Col lg={12}>
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="#">Client Portal</Breadcrumb.Item>
            <Breadcrumb.Item href="#">
              Users
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row><Col><h1>Client User Management</h1></Col></Row>
      <LoadingOverlay
        active={loading}
        spinner={<BounceLoader />}
        styles={{
          overlay: base => ({
            ...base,
            background: "rgba(91, 134, 96, 0.5)"
          }),
          content: base => ({
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center"
          })
        }}
        text="Loading..."
      >
      <ToolkitProvider
        keyField="id"
        data={ users }
        columns={ columns }
        search
      >
        {
          props => (
            <>
              <Row>
                <Col lg={6}>
                  <Link className="btn btn-primary" to="/client-portal/client-users/new">New Client User</Link>
                </Col>
                <Col lg={6} className="text-right">
                  <SearchBar { ...props.searchProps } />
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <BootstrapTable
                    { ...props.baseProps }
                    pagination={ paginationFactory() }
                  />
                </Col>
              </Row>
            </>
          )
        }
      </ToolkitProvider>
    </LoadingOverlay>

    </Container>
  );

};
