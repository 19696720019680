import React, { Component } from "react";
import { Col, Row, Modal, Button } from "react-bootstrap";
import { components } from "react-select";
import { retrieveToken } from "utils";
import QuestionManager from "managers/survey_definition/questionManager";
import QuestionIcon from "components/survey_definition/survey/helpers/QuestionIcon";
import Select from "react-select";
import MenuList from "components/survey_definition/survey/MenuList";
import { List } from "react-virtualized";

export default class AddQuestionCenteredModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      selectedValue: {},
      variableName: "",
      categoryName: "",
      questionLabel: "",
      surveyItemId: "",
      insert_question: {}
    };

    this.handleVariableChange = this.handleVariableChange.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.handleAddQuestion = this.handleAddQuestion.bind(this);
    this.IconOption = this.IconOption.bind(this);
  }

  IconOption = props => {
    return (
      <components.Option {...props} value={props.data.surveyItemWordingId}>
        {props.data.default ? (
          <QuestionIcon display={props.data.default} />
        ) : null}
        &nbsp;
        {props.data.label}
      </components.Option>
    );
  };
  handleSelectChange = selectedOption => {
    this.setState({ selectedValue: selectedOption });
  };
  handleVariableChange = event => {
    this.setState({ variableName: event.target.value });
  };
  handleCategoryChange = event => {
    this.setState({ categoryName: event.target.value });
  };

  handleAddQuestion = e => {
    e.preventDefault();
  };

  componentDidMount() {
    this.setState({ token: retrieveToken() });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.variable_name !== this.props.variable_name) {
      this.setState({ variableName: nextProps.variable_name });
    }
    if (nextProps.report_category !== this.props.report_category) {
      this.setState({ categoryName: nextProps.report_category });
    }
    if (
      nextProps.edit_question.surveyItemWordingId !==
      this.props.edit_question.surveyItemWordingId
    ) {
      this.setState({
        selectedValue: {
          label: nextProps.edit_question.label,
          value: nextProps.edit_question.surveyItemWordingId
        }
      });
    }
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Stay Metrics Question
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>Search For Your Question: </label>
          <Select
            components={{ MenuList, Option: this.IconOption }}
            getOptionValue={option => option.surveyItemWordingId}
            value={this.state.selectedValue}
            options={this.props.questions}
            onChange={event => {
              this.handleSelectChange(event);
              let questionManager = new QuestionManager(this.state.token);
              questionManager.getReportingById(event.surveyItemId).then(x => {
                this.setState({ insert_question: event });
                this.setState({ variableName: x.variable_name });
                this.setState({ categoryName: x.report_category });
              });
            }}
          />
          <Row className="addQuestionStyle">
            <Col lg={6}>
              <label>
                Variable Name:{" "}
                <input
                  type="text"
                  onChange={this.handleVariableChange}
                  value={this.state.variableName}
                />
              </label>
            </Col>
            <Col lg={6}>
              <label>
                Report Category:{" "}
                <input
                  type="text"
                  onChange={this.handleCategoryChange}
                  value={this.state.categoryName}
                />
              </label>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {this.props.is_new_question ? (
            <Button
              onClick={e => {
                this.props.insert_question({
                  variable_name: this.state.variableName,
                  category_name: this.state.categoryName,
                  question: this.state.insert_question
                });
                this.setState({ variableName: "" });
                this.setState({ categoryName: "" });
                this.setState({ selectedValue: {} });
                this.props.onHide();
              }}
            >
              Add Question
            </Button>
          ) : null}
          {this.props.is_new_question ? null : (
            <Button
              onClick={e => {
                this.props.update_question({
                  variable_name: this.state.variableName,
                  category_name: this.state.categoryName,
                  question: this.state.insert_question
                });
                this.setState({ variableName: "" });
                this.setState({ categoryName: "" });
                this.setState({ selectedValue: {} });
                this.props.onHide();
              }}
            >
              Update Question
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}
