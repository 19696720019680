import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  Col,
  Container,
  Row,
  InputGroup,
  Button,
  Tab,
  Nav,
  Form,
} from "react-bootstrap";
import { retrieveToken } from "utils";
import ClientManager from "managers/clientManager";
import PowerBIManager from "managers/powerBiReportManager";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import ClientInterventionThreshold from "components/client_portal/ClientInterventionThreshold";
import { Helmet } from 'react-helmet'

export default function Clients() {
  let clientManager = new ClientManager(retrieveToken());
  let powerBIManager = new PowerBIManager(retrieveToken());
  let [loading, setLoading] = useState(false);
  let [clientList, setClientList] = useState([]);
  let [workspaceList, setWorkspaceList] = useState([]);
  let [selectedClient, setSelectedClient] = useState();
  let [clientExist, setClientExist] = useState(false);
  let [client, setClient] = useState(ClientManager.blankObject());
  let [powerBiWorkspaceId, setPowerBiWorkspaceId] = useState("");
  let [showingAlert, setShowingAlert] = useState(false);
  let [thresholds, setThresholds] = useState([]);

  const handleSelectedClient = (e) => {
    setLoading(true);
    setPowerBiWorkspaceId("");
    setSelectedClient(e.target.options[e.target.selectedIndex]);
    clientManager
      .getClientById(e.target.options[e.target.selectedIndex].value)
      .then(client => {

        if (client.powerBiWorkspaceId !== null) {
          setPowerBiWorkspaceId(client.powerBiWorkspaceId);
        } else {
          setPowerBiWorkspaceId("");
        }

        if (client.stayMetricsId !== 0) {
          setClientExist(true);
        } else {
          setClientExist(false);
        }

        powerBIManager.getWorkspaces().then(ws => {
          setWorkspaceList(ws);
          setClient(client);
          setThresholds(client.interventionThreshold);
          setLoading(false);
        });
      });  
 
};

  const updateThreshold = (newThresh) =>{
    setThresholds(newThresh);
  }


  const handlePowerBiWorkspaceId = (e) => {
    let temp = client;
    temp.powerBiWorkspaceId = e.target.value;
    setClient(temp);
    setPowerBiWorkspaceId(e.target.value);
  };

  //Functions to update or insert a client
  const handleInsert = () => {
    setLoading(true);
    clientManager.insert(client).then((x) => {
      setLoading(false);
      setClientExist(true);
      handleShowAlert();
    });
  };
  const handleUpdate = () => {
    setLoading(true);
    clientManager.update(client.id, client).then((x) => {
      setLoading(false);
      handleShowAlert();
    });
  };
////////////////////////////////
  const handleShowAlert = () => {
    setShowingAlert(true);
    setTimeout(() => {
      setShowingAlert(false);
      window.location.href = "/client-portal/clients";
    }, 2000);
  };

  useEffect(() => {
    setLoading(true);
    setClientExist(true);
    clientManager.getList().then((clients) => {
      setClientList(clients);
    });

    clientManager.getList().then(clients => {
      setClientList(clients);
    });

    setLoading(false);
  }, []);
  
  return (
    <Container fluid={true}>
      <Helmet><title>StayMetrics Admin - Client Management</title></Helmet>
      <Row>
        <Col lg={12}>
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="#">Client Portal</Breadcrumb.Item>
            <Breadcrumb.Item href="#">Clients</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row>
        <Col>
          <h1>Client Management</h1>
        </Col>
      </Row>
      <LoadingOverlay
        active={loading}
        spinner={<BounceLoader />}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(91, 134, 96, 0.5)",
          }),
          content: (base) => ({
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
          }),
        }}
        text="Loading..."
      >
        <Col lg={12}>
          <div
            className={`alert alert-danger ${
              !clientExist ? "alert-shown" : "alert-hidden"
            }`}
          >
            <strong>This client does not exist in the portal</strong> If you
            would like to add them, please update the required fields
          </div>
          <div
            className={`alert alert-success ${
              showingAlert ? "alert-shown" : "alert-hidden"
            }`}
          >
            <strong>Success!</strong> Record has been saved.
          </div>

          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1">
                Sales Force Client
              </InputGroup.Text>
            </InputGroup.Prepend>
            <select
              className="form-control"
              onChange={handleSelectedClient}
              id="sel1"
            >
              <option value="">Client</option>
              {clientList &&
                clientList.map((x, i) => {
                  return (
                    <option key={i} value={x.id}>
                      {x.name}
                    </option>
                  );
                })}
            </select>
          </InputGroup>
        </Col>
        <Col lg={12}>&nbsp;</Col>
        {selectedClient && selectedClient !== "" ? (
          <>
            <Col lg={12}>
              <Tab.Container id="left-tabs-example" defaultActiveKey="powerbi">
                <Row>
                  <Col sm={3}>
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link eventKey="powerbi">Power BI Info</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="threshold">
                          Intervention Thresholds
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={9}>
                    <Tab.Content>
                      <Tab.Pane eventKey="powerbi">
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            Assigned Workspace
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <select
                          className="form-control"
                          onChange={handlePowerBiWorkspaceId}
                          value={powerBiWorkspaceId}
                          id="sel1"
                        >
                          <option value="">PowerBI Workspace</option>
                          {workspaceList &&
                            workspaceList.map((x, i) => {
                              return (
                                <option key={i} value={x.id}>
                                  {x.name}
                                </option>
                              );
                            })}
                        </select>
                      </InputGroup>
                      </Tab.Pane>
                      <Tab.Pane eventKey="threshold">
                      {thresholds && thresholds.length > 0 ? 
                      <ClientInterventionThreshold
                      thresholds={thresholds} 
                      handleThresholdUpdated={(target,value) => updateThreshold(target,value)} />
                      : '' }
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
              <Col sm={12}>&nbsp;</Col>
            </Col>
            <Col sm={12}>
              {clientExist ? (
                <Button
                  variant="success"
                  size="lg"
                  onClick={handleUpdate}
                  type="submit"
                >
                  Update
                </Button>
              ) : (
                <Button
                  variant="success"
                  size="lg"
                  onClick={handleInsert}
                  type="submit"
                >
                  Add Client
                </Button>
              )}
            </Col>
          </>
        ) : null}
      </LoadingOverlay>
    </Container>
  );
}
