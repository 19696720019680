import React from "react";
import {Field} from "formik";
import Form from "react-bootstrap/Form";

const QuestionInfo = (props) =>{
  return (
    <fieldset className="border p-2">
      <legend className="w-auto">Question Info</legend>
      <Form.Group controlId="questionNumber">
        <Form.Label>SMQ</Form.Label>
        <Field as={Form.Control}
                      placeholder={"SMQ#"}
                      value={props.questionNumber}
                      name="questionNumber"
                      className={props.touched.questionNumber && props.errors.questionNumber ? "is-invalid" : ""}
                      />
        <Form.Control.Feedback type="invalid">
          {props.errors.questionNumber}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="itemTypeId">
      <Field
        as={"select"}
        className={`form-control ${props.touched.itemTypeId && props.errors.itemTypeId ? "is-invalid" : ""}`}
        name={"itemTypeId"}
      >
        <option value={0}>
          Select Question Type
        </option>

        {props.itemTypes.map(itemType => {
          return <option key={itemType.id} value={itemType.id}>
            {itemType.description}
          </option>
        })}
      </Field>
        <Form.Control.Feedback type="invalid">
        {props.errors.itemTypeId}
      </Form.Control.Feedback>
      </Form.Group>

    </fieldset>
  );
};

export default QuestionInfo;
