import React from "react";
import { useAccordionToggle } from "react-bootstrap";
import { ArrowsExpand } from "react-bootstrap-icons";

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionToggle(eventKey);
  return (
    <ArrowsExpand className="iconStyle" size={32} onClick={decoratedOnClick}>
      {children}
    </ArrowsExpand>
  );
}
export default CustomToggle;
