import ClientService from "services/clientService";
import Client from "models/client";

export default class ClientManager {
  private clientService: ClientService;

  constructor(token: string) {
    this.clientService = new ClientService(token);
  }

  getList(): Promise<Client[]> {
    return this.clientService.getList()
      .then(x => {
        x.sort((a, b) => (a.name > b.name) ? 1 : -1)
        return x
      });
  }

  getClientById(id: number): Promise<Client> {
    return this.clientService.getClientById(id).then((x: Client) => x)
  }
 
  insert(client: Client): Promise<boolean> {
    return this.clientService.insertClient(client)
      .then(result => result);
  }

 update(id: string, client: Client): Promise<boolean> {
    return this.clientService.updateClient(id, client).then((result: boolean) => result)
  }

  static blankObject(): Client {
    return {
      id: "",
      name: "",
      powerBiWorkspaceId: "",
      stayMetricsId: 0,
      status: "",
      interventionThreshold: [
        {
          categoryName: "",
          clientSalesforceId: "",
          defaultIsEnabled: false,
          defaultThresholdValue: 0,
          interventionTypeId: 0,
          isEnabled: 0,
          longDesc: "",
          shortDesc: "",
          sortWithinCategory: 0,
          thresholdValue: 0,
        }
      ]
    };
  }

}
