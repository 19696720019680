import React, {useState} from 'react';
import {Container, Col, Badge} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";

import {Person, PeopleFill, AlertTriangle, Gear } from 'react-bootstrap-icons';
import SurveyErrors, {SurveyErrorsEntity} from './surveyErrors';
import {DownloadResponseRequestDocument} from 'models/surveyResponse/downloadResponseRequestEntity';

let DownloadResponseRequests = (props: DownloadResponseRequestProps) => {
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [rowIndex, setRowIndex] = useState<number>(-1); 
  const options = {month: 'numeric', day: 'numeric',year: 'numeric', hour: 'numeric', minute: 'numeric'};
  
// Array options
const selectRequestsArr = [{
  value: 'Single',
  label: 'Single'
}, {
  value: 'Mass Download',
  label: 'Mass Download'
}, {
  value: 'Automated',
  label: 'Automated'
}];

const selectStatusArr = [{
  value: 'Completed',
  label: 'Completed'
}, {
  value: 'Received',
  label: 'Received'
}, {
  value: 'Error',
  label: 'Error'
}];

  const requestTypeFormat = (cell, row) => {
   
    switch (row.requestType) {
      case 'Single':
        return (<Person className="iconStyle" size={24}/>);
      case 'Mass Download':
        return (<PeopleFill className="iconStyle" size={24}/>);
      case 'Automated':
        return (<Gear className="iconStyle" size={24}/>);  
    }
  }
  const viewErrorFormat = (cell, row: DownloadResponseRequestDocument, rowIndex) => {
    if (row.surveysReturned === null) return "";
    if (row.surveysReturned === 0) return (<AlertTriangle className={"warning"} size={28}/>);
    let erroredSurveys = row.ingressStates.filter(value => value.errorMessage !== undefined && value.errorMessage !== null);
    if (erroredSurveys.length == 0) return (<Badge variant={"success"} pill={true}>0</Badge> );

    return (
        <Badge 
          variant={"danger"} 
          onClick={() => {
   
            setRowIndex(row.downloadResponseRequestId);
            setShowErrors(true);
            
          }} 
          pill={true} >{erroredSurveys.length}
        </Badge>
    );
  }
  const parameterFormat = (cell, row) => {
    switch (row.requestType) {
      case 'Single':
        return `Response ID: ${cell.responderId}`;
      case 'Mass Download':
        let s = new Date(Date.parse(cell.startDate)).toLocaleDateString();
        let e = new Date(Date.parse(cell.endDate)).toLocaleDateString();
        return `${s} - ${e}`;
      case 'Automated':
          let s1 = new Date(Date.parse(cell.startDate)).toLocaleDateString();
          let e1 = new Date(Date.parse(cell.endDate)).toLocaleDateString();
          return `${s1} - ${e1}`;  
      default:
        return JSON.stringify(cell, undefined, 2);
    }
  }
  const requestTimeStampFormat = (cell) => {
    let temp = new Date(cell);
    return temp.toLocaleDateString("en-US", options);
  }

  const getErroredIngressStates = (downloadResponseRequest: DownloadResponseRequestDocument) : SurveyErrorsEntity[] => {
    if (downloadResponseRequest) {
    return downloadResponseRequest.ingressStates
        .filter(value => value.errorMessage !== undefined)
        .filter(value => value.errorMessage !== null)
        .map(value => {
          return {
            id: value.ingressStateId,
            recordedDate: new Date(Date.parse(downloadResponseRequest.requestedTimestamp)),
            surveyId: downloadResponseRequest.surveyId,
            providerId: value.providerId,
            surveyTitle: downloadResponseRequest.surveyTitle,
            errorMessage: value.errorMessage
          };
        })
      }
        else{
          return []
        }
  }

  const columns = [
    {
      dataField: "requestType",
      text: "",
      headerAlign: 'center',
      sort: true,
      filter: selectFilter({
        options: selectRequestsArr
      }),
      formatter: requestTypeFormat,
      headerStyle: () => {
        return {width: '5%'};
      },
    },
    {
      dataField: "surveyTitle",
      text: "Survey Title",
      sort: true,
      filter: textFilter(),
      headerStyle: () => {
        return {width: '20%'};
      },
    },
    {
      dataField: "parameters",
      text: "Parameters",
      formatter: parameterFormat,
      headerStyle: () => {
        return {width: '15%'};
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      filter: selectFilter({
        options: selectStatusArr
      }),
      headerStyle: () => {
        return {width: '15%'};
      },
    },
    {
      dataField: "surveysReturned",
      text: "Surveys Returned",
      sort: true,
      headerStyle: () => {
        return {width: '5%'};
      },
    },
    {
      dataField: "_dataFieldViewError",
      isDummyField: true,
      text: "Surveys Errored",
      sort: true,
      formatter: viewErrorFormat,
      headerStyle: () => {
        return {width: '5%'};
      },
    },
    {
      dataField: "requestedTimestamp",
      text: 'Date',
      type: "date",
      sort: true,
      formatter: requestTimeStampFormat,
      headerStyle: () => {
        return {width: '10%'};
      },
    },
  ];

  return (
    <Container fluid={true}>
      
        <BootstrapTable
          keyField="downloadResponseRequestId"
          data={props.downloadRequests}
          columns={columns}
          condensed
          filter={ filterFactory() }
          pagination={paginationFactory()}
        />
  

        <SurveyErrors 
          smToken={props.smToken} 
          erroredSurveys={getErroredIngressStates(props.downloadRequests.filter(x => x.downloadResponseRequestId === rowIndex)[0])}
          show={showErrors}
          onHide={() => {
            setShowErrors(false);
          }}
        />

    </Container>
  );
};

interface DownloadResponseRequestProps {
  downloadRequests: DownloadResponseRequestDocument[];
  smToken: string;
}

export default DownloadResponseRequests;
