import React from "react";
import { Col, Row, Modal, Button } from "react-bootstrap";

export default class AddSectionCenteredModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionName: props.section_name,
    };

    this.handleSectionChange = this.handleSectionChange.bind(this);
    this.handleAddSection = this.handleAddSection.bind(this);
  }

  handleSectionChange = (event) => {
    this.setState({ sectionName: event.target.value })
  };

  handleAddSection = (e) => {
    e.preventDefault();
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.section_name !== "" && prevState.sectionName === "" && nextProps.section_name !== prevState.sectionName) {
      return {
        sectionName: nextProps.section_name,
      };
    }
    else {
      return {
        sectionName: prevState.sectionName,
      };
    }
  }

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered >
        {this.props.children}
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Stay Metrics Section
        </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="addQuestionStyle">
            <Col lg={6}><label>Section Name: <input type="text" onChange={this.handleSectionChange} value={this.state.sectionName} /></label></Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {this.props.is_new_section ? <Button
            onClick={(e) => {
              this.props.insert_section({
                section_name: this.state.sectionName,
              });
              this.setState({ sectionName: "" });
              this.props.onHide();
            }}
          >Add Section</Button> : null}
          {this.props.is_new_section ? null : <Button
            onClick={(e) => {
              this.props.update_section({
                section_name: this.state.sectionName,
              });
              this.setState({ sectionName: "" });
              this.props.onHide();
            }}
          >Update Section</Button>}
        </Modal.Footer>
      </Modal>)
  }
}
