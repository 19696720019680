import React, {useState, useEffect} from "react";
import {Formik, Form, FieldArray, Field} from "formik";
import {Button, Col, Container, Row} from "react-bootstrap";
import DefinitionService from "services/survey_definition/definitions";
import {retrieveToken} from "utils";
import QuestionInfo from "./info";
import MatrixType from "./matrixType";
import QuestionNotes from "./notes";
import ResponseSet from "./responseSet";
import QuestionWordings from "./wording";
import QuestionMetaData from "./metaData";
import ResponseSetChoices from "./choices";
import {DisplayFormikState} from "components/helpers";
import QuestionManager from "managers/survey_definition/questionManager";
import * as Yup from 'yup';
import { Helmet } from 'react-helmet'

const QuestionSchema = Yup.object().shape({
  questionNumber: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),

  itemTypeId: Yup.number()
    .required('Required')
    .positive('Required'),

  wordings: Yup.array()
    .min(1, 'At least 1 is required'),

  responseSet: Yup.number()
    .required('Required')
    .positive('Required'),

  choices: Yup.array()
    .when("responseSet",
      {
        is: 2,
        then: Yup.array().min(2, 'You need at least 2 options to have a choice')
      })
    .when("responseSet",
      {
        is: 3,
        then: Yup.array().min(2, 'You need at least 2 options to have a choice')
      }),

  matrixType: Yup.number()
    .required('Required')
    .positive('Required'),

  matrixItems: Yup.array()
    .when("matrixType",
      {
        is: 2,
        then: Yup.array().min(1, 'You need at least 1 matrix item')
      }),

  supportingDataId: Yup.number()
    .when("matrixType", {
      is: 3,
      then: Yup.number().required('Required')
    }),
});


const QuestionForm = (props) => {
  let token = retrieveToken();
  let questionManager = new QuestionManager(token);

  const [question, setQuestion] = useState(QuestionManager.blankModel());

  const [surveyItemDefinition, setSurveyItemDefinition] = useState({
    approvalStatuses: [{id: 0, status: "unknown"}],
    itemTypes: [{id: 0, description: "unknown"}],
    responseSetTypes: [{id: 0, description: "unknown"}],
    surveyTypes: [{id: 0, description: "unknown", name: "unknown"}]
  });

  let definitionService = new DefinitionService(token);

  const formType = props.questionId === undefined ? 'new' : 'existing';
  let questionId = props.questionId;

  useEffect(() => {
    definitionService.retrieve()
      .then(def => {
        setSurveyItemDefinition(def.surveyItem);
      });

    if (formType === 'new') return;

    questionManager.getById(questionId)
      .then(y => {
        setQuestion(y)
      });
  }, []);

  const formSubmit = (values, {setSubmitting}) => {
    setSubmitting(true);

    if (formType === 'new') {
      questionManager.insert(values)
        .then(success => {
          if (success) {
            window.location.href = '/survey-definition/question';
          } else {
            alert("Saving Failed");
          }
        });
    } else {
      // Do update
      questionManager.update(questionId, values, question)
        .then(success => {
          if (success) {
            window.location.href = '/survey-definition/question';
          } else {
            alert("Saving Failed");
          }
        });
    }

    setSubmitting(false);
  };

  return (
    <Container fluid={true}>
      {!props.questionId 
      ? <Helmet><title>StayMetrics Admin - Add Question</title></Helmet>
      : <Helmet><title>StayMetrics Admin - Edit Question</title></Helmet>}

      <Formik enableReinitialize={true}
              initialValues={question}
              onSubmit={formSubmit}
              validationSchema={QuestionSchema}>
        {
          props => {
            const {values, isSubmitting} = props;
            return (
              <Form>
                <Row>
                  <Col lg={4}>
                    <QuestionInfo
                      {...props}
                      questionNumber={values.questionNumber}
                      itemTypes={surveyItemDefinition.itemTypes}
                      itemTypeId={values.itemTypeId}/>

                    <FieldArray
                      name="wordings"
                      component={QuestionWordings}
                    />
                  </Col>
                  <Col lg={4}>
                    <fieldset className="border p-2">
                      <legend className="w-auto">Question Response Set</legend>

                      <Field
                        types={surveyItemDefinition.responseSetTypes}
                        responseSet={values.responseSet}
                        choices={values.choices}
                        component={ResponseSet}
                      />

                      <FieldArray
                        name="choices"
                        responseSetTemplateId={values.responseSetTemplateId}
                        responseSetTemplateDescription={values.responseSetTemplateDescription}
                        component={ResponseSetChoices}
                      />
                    </fieldset>

                  </Col>
                  <Col lg={4}>
                    <fieldset className="border p-2">
                      <Field
                        component={MatrixType}
                        matrixType={values.matrixType}
                        matrixItems={values.matrixItems}
                        supportingDataId={values.supportingDataId}
                      />
                    </fieldset>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <QuestionMetaData ratingType={values.ratingType}
                                      reverseCoded={values.reverseCoded}/>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <QuestionNotes notes={values.notes}/>
                  </Col>
                </Row>
                <Button disabled={isSubmitting} variant="success" size="lg" type="submit">Submit</Button>
                <DisplayFormikState {...props}/>
              </Form>
            )
          }
        }

      </Formik>
    </Container>
  );
};

export default QuestionForm;
