import React, { useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import SurveyResponseManager from "managers/survey_response/surveyResponseManager";
import {ClientSurveyTemplate} from "models/clientSurvey";
import './surveyResponse.css';

let DownloadSingleQualtricsResponse = (props : DownloadSingleQualtricsResponseParameters) => {
  const [singleResponseDownload, setSingleResponseDownload] = useState("");
  const [surveyId, setSurveyId] = useState(0);

  let surveyResponseManager = new SurveyResponseManager(props.smToken);

  const submitDownloadRequest = () => {
    if (surveyId === 0 || singleResponseDownload === "") {
      alert("Please fill out the form properly");
    } else {
      surveyResponseManager
        .downloadSingleResponse(surveyId, singleResponseDownload)
        .then((response) => {
          if (response) {
            props.handleToast(true);
          } else {
            alert("Request failed");
          }
        });
    }
  };

  return (
      <Col lg="12" className="ResponseDownload">
      <Col lg="12">
      <h2>Single Response</h2>
        <Form.Group controlId="singleSurveyId">
          <Form.Label>Qualtrics Survey</Form.Label>
          <Form.Control
            as="select"
            value={surveyId.toString()}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSurveyId(parseInt(event.target.value));
            }}
          >
            <option value={0}>Select Survey</option>
            {props.surveys.map((item, index) => {
              return (
                <option key={index} value={item.id}>
                  {item.title}
                </option>
              );
            })}
          </Form.Control>
        </Form.Group>
      </Col>
      <Col lg="12">
        <Form.Group controlId="singleResponseDownload">
          <Form.Label>Response ID</Form.Label>
          <Form.Control
            type="text"
            placeholder="Qualtrics Response ID"
            defaultValue={singleResponseDownload}
            onChange={(event) => {
              setSingleResponseDownload(event.target.value);
            }}
          />
        </Form.Group>
      </Col>
      <Col lg="12">
        <Button variant="primary" block onClick={()=> {submitDownloadRequest(); props.handleClose(true)}}>
            Download
        </Button>
      </Col>
    </Col>
  );
};
interface DownloadSingleQualtricsResponseParameters {
   surveys: ClientSurveyTemplate[];
   handleToast: any;
   handleClose: any;
   smToken: string;
}
export default DownloadSingleQualtricsResponse;
