import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Row} from "react-bootstrap";
import {Field, Form, Formik} from "formik";
import QuestionManager from "managers/survey_definition/questionManager";
import {retrieveToken} from "utils";
import BootstrapForm from "react-bootstrap/Form";

const QuestionReportingData = (props) => {
  let token = retrieveToken();
  let questionManager = new QuestionManager(token);
  const [questionId, setQuestionId] = useState(props.questionId);
  const [constructs, setConstructs] = useState([]);
  const [question, setQuestion] = useState({
    variable_name: "",
    report_category: "",
    constructName: ""
  });

  const formType = props.questionId === undefined ? 'new' : 'existing';

  useEffect(() => {
    questionManager.getConstructsList().then(x => setConstructs(x));

    if(formType === 'existing') {
      questionManager.getReportingById(questionId)
        .then(x => {
          setQuestion(x);
        });
    }
  }, []);

  const formSubmit = (values, {setSubmitting}) => {
    setSubmitting(true);
    questionManager.updatedReporting(questionId, values);
    setSubmitting(false);
    window.location.href = '/survey-definition/question';
  };

  return(
    <Container fluid={true}>

      <Formik enableReinitialize={true}
              initialValues={question}
              onSubmit={formSubmit}
      >
        {
          props => {
            return (
              <Form>
                <Row>
                  <Col sm={6}>
                    <div className="form-group">
                      <label>Variable Name</label>
                      <Field
                        className="form-control"
                        name="variable_name"
                        value={props.values.variable_name}
                      />
                    </div>

                    <div className="form-group">
                      <label>Report Category</label>
                      <Field
                        className="form-control"
                        name="report_category"
                        value={props.values.report_category}
                      />
                    </div>
                    <div className="form-group">
                      <label>Select Construct</label>
                      <Field
                        as="select"
                        className={`form-control`}
                        name={"constructName"}
                        value={props.values.constructName}
                      >
                        <option value={0}>Constructs</option>
                        {constructs && constructs.map(construct => {
                          return <option key={construct.id} value={construct.name}>
                            {construct.name}
                          </option>
                        })}
                      </Field>
                      <BootstrapForm.Control.Feedback type="invalid">
                      </BootstrapForm.Control.Feedback>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={4}>
                    <Button disabled={props.isSubmitting} variant="success" size="lg" type="submit">Submit</Button>
                  </Col>
                </Row>
              </Form>
            )}}
      </Formik>

    </Container>
  );

};

export default QuestionReportingData;
