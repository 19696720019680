import Axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import {
  ClientSurveyItem,
  ClientSurveyItemReporting,
  ClientSurveyTemplate,
  ClientSurvey
} from "models/clientSurvey";
import { Section } from "managers/survey_definition/models";
import QuestionList from "components/survey_definition/question/QuestionList";

class SurveyService {
  private client: AxiosInstance;

  constructor(jwt: string) {
    let config: AxiosRequestConfig = {
      baseURL: process.env.REACT_APP_API_BASEURL,
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + jwt
      }
    };
    this.client = Axios.create(config);
  }

  list(): Promise<ClientSurveyTemplate[]> {
    return this.client
      .get<ClientSurveyTemplate[]>("api/v2/survey-definition/client-survey")
      .then((question_list: { data: ClientSurveyTemplate[] }) => question_list.data)
      .catch((er: any) => {
        console.log(er);
        return [];
      });
  }

  getById(id: string): Promise<ClientSurveyTemplate> {
    return this.list()
      .then((x: ClientSurveyTemplate[]) =>
        x.filter(item => item.id === parseInt(id))
      )
      .then((x: ClientSurveyTemplate[]) => x[0]);
  }

  insert(survey: ClientSurveyTemplate): Promise<any> {
    return this.client
      .post("api/v2/survey-definition/client-survey", survey)
      .then((x: any) => x.data.id)
      .catch((er: any) => console.log(er));
  }

  update(id: string, survey: ClientSurveyTemplate): Promise<any> {
    return this.client
      .put(`api/v2/survey-definition/client-survey/${id}`, survey)
      .then((x: any) => x.data.id)
      .catch((er: any) => console.log(er));
  }

  updateContent(id: string, survey: ClientSurveyItem[]): Promise<any> {
    return this.client
      .put(`api/v2/survey-definition/client-survey/${id}/items`, survey)
      .then((x: any) => x)
      .catch((er: any) => console.log(er));
  }

  getContentById(id: string): Promise<{ data: ClientSurveyItem[] }> {
    return this.client
      .get<ClientSurveyItem[]>(
        `api/v2/survey-definition/client-survey/${id}/items`
      )
      .then((x: AxiosResponse<ClientSurveyItem[]>) => x)
      .catch((er: any) => {
        console.log(er);
        return { data: [] };
      });
  }

  getSurveyInfoById(id: string): Promise<{ data: ClientSurvey }> {
    return this.client
      .get<ClientSurvey>(
        `api/v2/survey-definition/client-survey/${id}`
      )
      .then((x: AxiosResponse<ClientSurvey>) => x)
      .catch((er: any) => {
        console.log(er);
        return { data: null };
      });
  }

  getReportingById(id: string): Promise<{ data: ClientSurveyItemReporting }> {
    return this.client
      .get<ClientSurveyItemReporting>(
        `api/v2/survey-definition/client-survey-item/${id}/reporting`
      )
      .then((x: AxiosResponse<ClientSurveyItemReporting>) => x)
      .catch((er: any) => {
        console.log(er);
        return {
          data: { variableName: "", reportCategory: "", constructName: "" }
        };
      });
  }

  getSurveyTemplates(): Promise<{ data: any }> {
    return this.client
      .get<ClientSurveyTemplate[]>("api/v2/survey-definition/client-survey/")
      .then((x: AxiosResponse<ClientSurveyTemplate[]>) => x)
      .catch((er: any) => {
        console.log(er);
        return { data: [] };
      });
  }

  static getBlank(): ClientSurveyTemplate {
    return {
      id: 0,
      copyFromClientSurveyId: 0,
      startDate: "",
      endDate: "",
      clientId: "",
      stayMetricsId: 0,
      clientName: "",
      participantTypeText: "",
      surveyTypeId: 0,
      surveyTypeText: "",
      title: "",
      notes: "",
      qualtricsSurveyId: "",
      surveyStart: "",
      surveyEnd: "",
      autoImport: false,
    };
  }

  static getBlankSurvey(): { data: Section[] } {
    let blank = {
      data: [] as Section[]
    };
    return blank;
  }
}

export default SurveyService;
