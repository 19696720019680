import React, {Component } from "react";
import { Container, Row, Col, FormControl, InputGroup } from "react-bootstrap";

class ClientInterventionThreshold extends Component {
  constructor(props) {
    super(props);

    this.state = {
      thresholds: [],
      catName: [],
      test: ""
    };
  }

  handleInputChange = (targetId, event) => {
    let temp = this.state.thresholds;
    let item =  temp.filter((x) => x.interventionTypeId === targetId)[0];
    item.thresholdValue = parseInt(event.target.value);

    this.setState({thresholds: temp});
    this.props.handleThresholdUpdated(temp);
  };

  handleCheckBox = (targetId, event) => {
    let temp = this.state.thresholds;
    let item =  temp.filter((x) => x.interventionTypeId === targetId)[0];

    if (!event.target.checked){
      item.thresholdValue = 0;
      item.isEnabled = 0;
    }
    else if (event.target.checked){
      item.thresholdValue = item.defaultThresholdValue;
      item.isEnabled = 1;
    }
    this.setState({thresholds: temp});
    this.props.handleThresholdUpdated(temp);
  };
  
  componentDidMount () {
    this.setState({thresholds: this.props.thresholds});
    this.setState({catName: [...new Set(this.props.thresholds.map((x) => x.categoryName))]});
  }

  render() {
    return (
      <Container>
        {this.state.catName &&
          this.state.catName.map((value, key) => {
            const th = this.state.thresholds.filter((x) => x.categoryName === value);
            return (
              <div key={key}>
                <h4>{value}</h4>
                <Row key={key}>
                  {th &&
                    th.map((value, key) => {
                      return (
                        <Col key={key} lg={4}>
                          <label className="thresholdLabel">{value.shortDesc}</label>
                          <InputGroup key={key} className="mb-3">
                            <InputGroup.Prepend>
                              <InputGroup.Checkbox
                                key={key}
                                onClick={(event) =>
                                  this.handleCheckBox(value.interventionTypeId, event)
                                }
                                defaultChecked={value.isEnabled}
                              />
                            </InputGroup.Prepend>
                            {value.isEnabled ?
                            <FormControl
                              as="input"
                              key={key}
                              onChange={(event) =>
                                this.handleInputChange(value.interventionTypeId, event)
                              }
                             defaultValue={value.thresholdValue}
                            /> : null}
                          </InputGroup>
                        </Col>
                      );
                    })}
                </Row>
              </div>
            );
          })}
      </Container>
    );
  }
}

export default ClientInterventionThreshold;
